@import url("https://fonts.googleapis.com/css2?family=Sacramento:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 29, 29, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 0, 0.399);
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPaper-root.MuiDialog-paper {
  background-color: transparent !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInput-underline:hover {
  border-bottom: none !important;
}

.MuiSwitch-root .MuiSwitch-track {
  background-color: #d9d9d9 !important;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #eb0000 !important;
}

.MuiSvgIcon-root {
  height: 30px !important;
  width: 30px !important;
  box-sizing: content-box !important;
}

.MuiTypography-root {
  font-family: unset !important;
  font-weight: unset !important;
  font-size: unset !important;
}

.header {
  background-color: #1212bc;
  transition: backdrop-filter 0.3s ease, background-color 0.3s ease;
}

.header-blur {
  backdrop-filter: blur(10px);
  background-color: rgb(255, 255, 255);
}

.video-player {
  object-fit: cover;
}

.recharts-legend-item-text {
  color: gray !important;
}

/* Custom styles for react-slick dots */
.slick-dots {
  bottom: 5px;
}

.slick-dots li button::before {
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
}

.Visibility {
  height: 23px !important;
  width: 23px !important;
}

.thumbnail-img video {
  width: 56px !important;
  border-radius: 18px;
  object-fit: cover;
}

.react-datepicker__header {
  background-color: #ffe9e9 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #eb0000 !important;
  align-items: center !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex !important;
}

.react-datepicker__day--selected {
  background-color: #eb0000 !important;
}

.react-datepicker__current-month {
  color: #eb0000 !important;
}

.react-datepicker-time__header {
  color: #eb0000 !important;
}

.react-datepicker__day--in-range {
  background-color: #eb0000 !important;
}

.react-datepicker__day--in-range:hover {
  background-color: #fc9e9e !important;
}

/* CustomDatePicker.css */

.react-datepicker__day--in-selecting-range {
  background-color: #eb0000 !important;
  /* Change to your desired hover color */
}

/* @keyframes slideInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */
@media (min-width: 1280px) and (max-width: 1400px) {
  .profile > *:not(:last-child) {
    margin-right: -12px !important;
    font-size: smaller !important;
  }
}

@media (min-width: 300px) and (max-width: 470px) {
  .Toastify__toast-container {
    width: 96vw;
    padding: 0;
    left: 5px;
    margin: 0;
    top: 3px;
  }

  .login-container {
    margin-top: 40px !important;
    padding: 20px 1px 0px 5px;
  }

  .edit-profile {
    min-width: 320px !important;
  }

  .login-title {
    font-size: 20px !important;
  }

  .login-text {
    font-size: 14px !important;
    margin-top: 5px;
  }

  .login-suggestion {
    font-size: 16.5px !important;
  }

  .login-bottom {
    font-size: 13px !important;
  }

  /* admin */
  .title-child {
    font-size: 13px;
  }

  .scheduled {
    padding: 10px;
  }

  .scheduled .scheduled-sub {
    gap: 4px;
  }

  .scheduled .scheduled-user {
    height: 35px;
    width: 35px;
    border-radius: 15px !important;
  }

  .scheduled .scheduled-username {
    font-size: 12px;
  }

  .scheduled .scheduled-name {
    font-size: 14px;
  }

  .scheduled .scheduled-status {
    font-size: 10px;
    padding: 4px;
  }

  .scheduled .right-side {
    padding: 0px;
    gap: 4px;
  }

  .scheduled .scheduled-delete {
    height: 30px;
    width: 30px;
  }

  .recent-btn {
    padding-top: 12px !important;
  }
}

.css-12sw0nx-MuiDialogContent-root {
  padding: 0px !important;
}

.channel-scrollbar::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
}

.channel-scrollbar::-webkit-scrollbar-thumb {
  background-color: #fc9e9e !important;
  border-radius: 10px !important;
}

.channel-scrollbar::-webkit-scrollbar-track {
  background-color: #f04848 !important;
}

/* For Firefox */
.channel-scrollbar {
  scroll-behavior: smooth;
  scrollbar-color: #fc9e9e #f6e6e6;
  scrollbar-width: thin;
}

@media (min-width: 300px) and (max-width: 400px) {
  .nav-inp {
    width: 120px;
  }

  .user-dashboard {
    height: 350px !important;
    min-width: 270px !important;
  }

  .admin-dashboard {
    height: 300px !important;
  }

  .admin-label {
    font-size: 12px !important;
  }

  .admin-count {
    font-size: 16px;
  }

  .admin-title {
    font-size: 18px;
  }

  .admin-month {
    font-size: 11.5px;
  }

  .admin-lasets {
    font-size: 11.5px;
  }

  .recharts-default-tooltip {
    font-size: 10px !important;
  }

  .recharts-legend-wrapper ul {
    margin-bottom: 10px !important;
  }

  .recent-text h5 {
    font-size: 15px !important;
  }

  .recent-text p {
    font-size: 14px !important;
  }

  .recent-bottom {
    font-size: 14px;
    margin-left: 2px;
  }

  .channel-box {
    height: 120px;
    width: 100%;
  }

  .channel-innerbox {
    height: 35px;
    width: 100%;
  }

  .channel-text {
    font-size: 16px;
  }

  .channel-text2 {
    font-size: 14px;
  }

  .social-text {
    font-size: 14px !important;
  }

  .social-name {
    font-size: 10px;
  }

  .upload-header {
    font-size: 18px;
  }

  .upload-save {
    font-size: 13px;
  }

  .singlepost-head {
    font-size: 13.5px;
  }

  .editprofile-dailog {
    width: 240px !important;
  }

  .no-scheduled {
    font-size: 15px;
    padding-bottom: 0px;
  }

  .scheduled-text {
    font-size: 12px !important;
  }

  .upload-post-date .react-datepicker {
    width: 100% !important;
  }

  .single-post-img img {
    height: 13px;
    width: 13px;
  }

  .single-post-img p {
    font-size: 12px;
  }
}

.react-datepicker {
  font-family: "Montserrat" !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #eb0000 !important;
  color: white !important;
  font-weight: 600 !important;
}

.react-datepicker__day:hover {
  background-color: #fc9e9e;
  color: white !important;
  font-weight: 600 !important;
}

.react-datepicker__day--selected {
  background-color: #eb0000 !important;
  color: white !important;
  font-weight: 600 !important;
}

.react-datepicker__day:hover {
  background-color: #fc9e9e !important;
  color: white !important;
  font-weight: 600 !important;
}

/* date-picker */
@media (min-width: 768px) and (max-width: 1024px) {
  .upload-post-date .react-datepicker__month-container {
    width: 200px !important;
  }
}

@media (min-width: 1024px) {
  .edit-datepicker .react-datepicker__month-container {
    width: 460px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .edit-datepicker .react-datepicker__month-container {
    width: 350px !important;
  }
}

@media (min-width: 640px) {
  .edit-datepicker .react-datepicker__month-container {
    width: 350px;
  }
}

@media (max-width: 640px) {
  .edit-datepicker .react-datepicker__month-container {
    width: 100% !important;
  }
}

input[type="date"] {
  font-family: "Montserrat", sans-serif !important;
}

.font-sans {
  font-family: unset !important;
}

.css-1pco1d0-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 26px !important;
  transition: opacity 0.3s ease, transform 0.2s ease;
  box-shadow: 0 0 6px #00000035 !important;
  border: 0.5px solid #00000017 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.slick-dots {
  position: absolute;
  bottom: -22px;
}

.slick-dots li {
  margin: 0 -4.1px;
  position: relative;
}

.slick-dots li button {
  width: 40px;
  height: 54px;
  background-color: transparent;
  border: none;
  padding: 0;
}

/* Style for inactive dots */
.slick-dots li button::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Inner dot for inactive state */
.slick-dots li button::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  background-color: lightslategray;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-dots li.slick-active button::after {
  background-color: #ff1d1d;
  /* Active dot color */
}

/* Hover effect */
.slick-dots li button:hover::after {
  background-color: #777;
  /* Dot color on hover */
}

/* Small device-specific styling */
@media (max-width: 480px) {
  .slick-dots li button {
    width: 20px;
    height: 20px;
  }

  .gridpost .slick-dots li {
    margin: 0 -4.1px;
    position: relative;
  }
}

.gridpost .slick-dots li button {
  width: 40px;
  background-color: transparent;
  border: none;
  padding: 0;
}

.gridpost .slick-dots li button::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gridpost .slick-dots li button::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gridpost .slick-dots li.slick-active button::after {
  background-color: #ff1d1d;
}

.gridpost .slick-dots li button:hover::after {
  background-color: #777;
}

@media (max-width: 480px) {
  .slick-dots li button {
    width: 20px;
    height: 20px;
  }

  .gridpost .slick-dots li {
    margin: 0 1px;
  }
}

.css-3jswtl-MuiToolbar-root {
  min-height: 49px !important;
}
.description-inp {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.881) !important;
}
