.layout-wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    margin-top: 60px;
}

.css-9t6vi9-MuiDrawer-docked .MuiDrawer-paper{
    width: 60px;
    box-sizing: border-box;
    margin-top: 3px !important;
    position: unset !important;
    display: flex;
    justify-content:center !important;
    /* align-items: stretch; */
    flex-direction: column;
     padding-left: 10px 4px!important; 
  /* align-items: center !important; */
  }
  
  @media (min-width: 900px) {
    .css-1jyac7y-MuiDrawer-docked .MuiDrawer-paper {
        margin-top: 65px !important; 
    }
  }
  @media (min-width: 1200px) {
    .css-1riq24d {
        margin-left: 0 !important;
        width: calc(100% - 60px) !important;
    }
  }